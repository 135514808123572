<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.projectManager.menu"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n code="entities.projectManager.list.title"></app-i18n>
      </h1>

      <app-project-manager-list-toolbar></app-project-manager-list-toolbar>
      <app-project-manager-list-filter></app-project-manager-list-filter>
      <app-project-manager-list-table></app-project-manager-list-table>
    </div>
  </div>
</template>

<script>
import ProjectManagerListFilter from '@/modules/project-manager/components/project-manager-list-filter.vue';
import ProjectManagerListTable from '@/modules/project-manager/components/project-manager-list-table.vue';
import ProjectManagerListToolbar from '@/modules/project-manager/components/project-manager-list-toolbar.vue';

export default {
  name: 'app-project-manager-list-page',

  components: {
    [ProjectManagerListFilter.name]: ProjectManagerListFilter,
    [ProjectManagerListTable.name]: ProjectManagerListTable,
    [ProjectManagerListToolbar.name]: ProjectManagerListToolbar,
  },
};
</script>

<style>
</style>
